<template>
  <div class="container">
    <div class="title">公司介绍</div>
    <div class="line"></div>
    <div v-loading = "loading">
      <div class="content">
        <div
            v-if="dataInfo.profile"
            v-html="dataInfo.profile"
        ></div>
        <div v-else>-</div>
      </div>
      <div class="footer marginT24">
        <div class="footTitle footWidth1">
          <div class="footContent1">成立时间</div>
          <div class="footContent2">{{ dataInfo.found_year | textFormat }}</div>
        </div>
        <div class="footTitle footWidth2">
          <div class="footContent1">企业规模</div>
          <div class="footContent2">{{ dataInfo.scale | staffsizeFormat(LOCALE) }}</div>
        </div>
        <div class="footTitle footWidth3">
          <div class="footContent1">注册资金</div>
          <div class="footContent2">{{ dataInfo.registered_capital | textFormat }}</div>
        </div>
      </div>
      <div class="footer marginT16">
        <div class="footTitle footWidth1">
          <div class="footContent1">联系电话</div>
          <div class="footContent2">
            <span v-if="IS_LOGIN">{{ dataInfo.telephone | textFormat }}</span>
            <span class="to_login" v-else type='text'  @click="to_login">请登录</span>
          </div>
        </div>
        <div class="footTitle footWidth2">
          <div class="footContent1">邮箱</div>
          <div class="footContent2">
            <span v-if="IS_LOGIN">{{ dataInfo.email | textFormat }}</span>
            <span class="to_login" v-else type='text'  @click="to_login">请登录</span>
          </div>
        </div>
        <div class="footTitle footWidth3">
          <div class="footContent1">地址</div>
          <div class="footContent2" :title="dataInfo.address_en | priorFormat(dataInfo.address_zh, LOCALE)">{{ dataInfo.address_en | priorFormat(dataInfo.address_zh, LOCALE) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { priorFormat } from '@/basePlugins/filters'
  export default {
    name: "companyBrief",
    props: {
      company_id: {
        type: String | Number,
        default: ''
      }
    },
    data() {
      return {
        loading: false,
        dataInfo: {}
      }
    },
    created() {
      this.getData()
    },
    methods: {
      to_login(){
         this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      },
      async getData() {
        let params = {
          company_id: this.company_id,
        }
        this.loading = true
        try {
          let res = await this.$store.dispatch("API_company/getCompanyBrief",params)
          this.loading = false
          if (res.success) {
            res.data.profile = priorFormat(res.data.profile_en, res.data.profile_zh, this.LOCALE)
            if (res.data.found_year === 0) {
              res.data.found_year = ''
            }
            this.dataInfo = Object.assign({}, res.data)
          }
        } catch (e) {
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .container {
    padding: 24px 24px 32px;
    background: #FFFFFF;
    .title {
      color: #333333;
      font-size: 16px;
      font-weight: 800;
    }
    .to_login{
      cursor: pointer;
      color: #0083F6
    }
    .line {
      height: 1px;
      background: #DCDFE6;
      width: 100%;
      margin-top: 20px;
    }
    .content {
      margin-top: 16px;
      color: #333333;
      margin-bottom: 8px;
        word-break:break-all;
    }
    .footer {
      display: flex;
      justify-content: space-between;
      .footTitle {
        display: flex;
        .footContent1 {
          width: 76px;
          font-weight: 800;
          color: #333333;
        }
        .footContent2 {
          font-weight: 400;
          color: #1F292E;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .footWidth1 {
        width: 31%;
      }
      .footWidth2 {
        width: 31%;
      }
      .footWidth3 {
        width: 38%;
      }
    }

  }
  .marginT24 {
    margin-top: 24px;
  }
  .marginT16 {
    margin-top: 16px;
  }
</style>